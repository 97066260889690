const HELP_URL = {
  HelpMdFileStoreUrl: '../../helpMarkdownFile/',
  Home: 'home',
  Discover: 'discover',
  MyAsset: 'myAsset',
  AllAssets: 'allAssets',
  APIs: 'APIs',
  REGISTER_API: 'RegisterApi',
  SWAGGER_API: 'SwaggerApi',
  EDIT_APIs: 'editApis',
  Code_Library: 'codeLibrary',
  EDIT_Code_Library: 'editCodeLibrary',
  Storybook: 'storybook',
  TechDocs: 'techDocs',
  ExternalDocumentation: 'externalDocumentation',
  RegisterExternalDocumentation: 'registerExternalDocumentation',
  Review: 'review',
  ReviewComponentPageOverview: 'reviewComponentPageOverview',
  ReviewComponentPageReadme: 'reviewComponentPageReadme',
  ReviewComponentPageTECHDOC: 'reviewComponentPageTechdoc',
  ReviewComponentPageREVIEW: 'reviewComponentPageReview',
  ReviewAPIPageOVERVIEW: 'ReviewAPIPageOverview',
  ReviewAPIPageDEFINITION: 'ReviewAPIPageDefinition',
  ReviewAPIPageDocuments: 'ReviewAPIPageDocuments',
  ReviewAPIPageREVIEW: 'ReviewAPIPageReview',
  ADMIN_Page: 'adminPage',
  MANAGE_ADMINS: 'manageAdmins',
  Exclusion_List: 'exclusionList',
  MANAGE_PROMPTS: 'managePrompts',
  VIEW_PROMPTS: 'viewPrompts',
  ADD_PROMPTS: 'addPrompts',
  SIMILAR_PROMPTS: 'similarPrompts',
  MANAGE_REVIEWERS: 'manageReviewers',
  MANAGE_REVIEWERS_All_CodeReviewers: 'manageReviewersAllCodeReviewers',
  MANAGE_REVIEWERS_All_APIReviewers: 'manageReviewersAllApiReviewers',
  COST_AVOIDANCE_MAIN: 'costAvoidanceMain',
  COST_AVOIDANCE_VARIABLES: 'costAvoidanceVariables',
  COST_AVOIDANCE_VARIABLES_UPDATE: 'costAvoidanceVariablesUpdate',
  COST_AVOIDANCE_VARIABLES_ADD: 'costAvoidanceVariablesAdd',
  COST_AVOIDANCE_COMPLEXITY: 'costAvoidanceComplexity',
  COST_AVOIDANCE_COMPLEXITY_UPDATE: 'costAvoidanceComplexityUpdate',
  SUBSCRIBED: 'subscribed',
  TAXONOMY_PAGE: 'taxonomyPage',
  VIEW_TAXONOMY: 'viewTaxonomy',
  ADD_CAPABILITY: 'AddCapability',
  TAXANOMYEDIT_CAPABILITY: 'taxanomyEditCapability',
  GRAPH_TAXONOMY: 'TO be decided',
  PROMOTION_MARKETING: 'promotionMarketing',
  MANAGE_PROMOTIONS: 'managePromotion',
  ADD_PROMOTIONS: 'addPromotion',
  UPDATE_PROMOTIONS: 'updatePromotion',
  feedbackUrl: 'feedback',
  editFeedback: 'editFeedback',
  registerFeedback: 'registerFeedback',
  mockApiUrl: 'mockApi',
  registerMock: 'registerMock',
  testMock: 'testMock',
  editMock: 'editMock',
  SoftwareTemplates: 'SoftwareTemplates',
  leaderBoard: 'leaderBoard',
  adhocPoints: 'adhocPoints',
  leaderBoardDetails: 'leaderBoardDetails',
  topPlayers: 'topPlayers',
  updateAdhocPoints: 'updateAdhocPoints',
  addEditLevelAndBadges: 'addEditLevelAndBadges',
  levelAndBadges: 'levelAndBadges',
  gamificationControl: 'gamificationControl',
  ADMIN_TOOLBOX_LANDING_PAGE: 'adminToolboxLandingPage',
  ADMIN_TOOLBOX_MANAGE_TOOLS: 'adminToolboxManageTools',
  ADMIN_TOOLBOX_ADD_EDIT_TOOL: 'adminToolboxAddEditTool',
  ADMIN_DATAINSIGHT_LANDING_PAGE: 'adminDataInsightsLandingPage',
  ADMIN_DATAINSIGHT_MANAGE_CATEGORY: 'adminDataInsightsManageCategory',
  ADMIN_DATAINSIGHT_MANAGE_ITEMS: 'adminDataInsightsManageItems',
  DATAINSIGHTS_PAGE: 'dataInsightsPage',
  POC_LANDING_PAGE: 'pocLandingPage',
  ADD_POC_PAGE: 'addPocPage',
  POC_DETAILS_PAGE: 'pocDetailsPage',
  ADDITIONAL_POC_PAGE: 'additionalPOCPage',
  EDIT_POC_PAGE: 'editPocPage',
  CLONE_POC_PAGE: 'clonePocPage',
  PROVISIONING_CENTER_LANDINGPAGE: 'provisioningCenterLandingPage',
  PROVISIONING_CENTER_SERVICES: 'provisioningCenterServices',
  PROVISIONING_CENTER_APMINFO: 'provisioningCenterApmInfo',
  PROVISIONING_CENTER_ACTIVITY: 'provisioningCenterActivity',
  PROVISIONING_CENTER_ADMINS: 'provisioningCenterAdmins',
  PERFORMANCE_TEST_RUNS_PAGE: 'runsPerformanceTest',
  PERFORMANCE_TEST_MANAGECONFIG_PAGE: 'manageConfigPerformanceTest',
  PERFORMANCE_TEST_ADDCONFIG_PAGE: 'addConfigPerformanceTest',
  PROMPT_STORE: 'promptStore',
  Whats_New_Steplist: 'whatsNewStepsPage',
  Whats_New_Pagelist: 'whatsNewPageList',
  TOOLBOX_PAGE: 'toolboxPage',
  INFO_EXCHANGE_ALL_QUESTION_PAGE: 'informationExchangeAllQuestion',
  INFO_EXCHANGE_ASK_A_QUESTION_PAGE: 'informationExchangeAskAQuestion',
  INFO_EXCHANGE_POST_DETAILS_PAGE: 'informationExchangePostDetails',
  updateRewardsPoints: 'updateRewardsPoints',
  // Home:"https://confluence.cbre.com/x/iB7JBg",
  VISUAL_REGRESSION: 'visualRegression',
  CAPABILITY_MAPPING: 'capabilityMapping',
  RELEASE_NOTES: 'releaseNote',
  TOOLBOX_DETAILS: 'toolboxDetails',
  UTILITY_TOOLS_HOMEPAGE: 'utilityToolsHomePage',
  UTILITY_TOOLS_DETAILS_PAGE: 'utilityToolDetailsPage',
  CODE_QUALITY_PAGE: 'codeQualityPage',
  // Discover:"https://confluence.cbre.com/x/mR7JBg",
  // MyAsset:"https://confluence.cbre.com/x/nB7JBg",
  // AllAssets:"https://confluence.cbre.com/x/nh7JBg",
  // APIs:"https://confluence.cbre.com/x/oh7JBg",
  // EDIT_APIs: 'https://confluence.cbre.com/x/NR-JBg',
  // Code_Library: 'https://confluence.cbre.com/x/pB7JBg',
  // EDIT_Code_Library: 'https://confluence.cbre.com/x/NR-JBg',
  // Storybook:
  //   'https://github.com/CBRE-Shared-Code/DevXUserGuide/blob/main/Storybook.md',
  // TechDocs: 'https://confluence.cbre.com/x/qR7JBg',
  // ExternalDocumentation: 'https://confluence.cbre.com/x/yh7JBg',
  // RegisterExternalDocumentation: 'https://confluence.cbre.com/x/zB7JBg',
  // Review: 'https://confluence.cbre.com/x/0B7JBg',
  // ReviewComponentPageOverview: 'https://confluence.cbre.com/x/zh7JBg',
  // ReviewComponentPageReadme: 'https://confluence.cbre.com/x/0h7JBg',
  // ReviewComponentPageTECHDOC: 'https://confluence.cbre.com/x/1B7JBg',
  // ReviewComponentPageREVIEW: 'https://confluence.cbre.com/x/1x7JBg',
  // ReviewAPIPageOVERVIEW: 'https://confluence.cbre.com/x/zh7JBg',
  // ReviewAPIPageDEFINITION: 'https://confluence.cbre.com/x/2R7JBg',
  // ReviewAPIPageREVIEW: 'https://confluence.cbre.com/x/1x7JBg',
  // ADMIN_Page: 'https://confluence.cbre.com/x/kR7JBg',
  // MANAGE_ADMINS: 'https://confluence.cbre.com/x/uB7JBg',
  // MANAGE_REVIEWERS: 'https://confluence.cbre.com/x/uh7JBg',
  // MANAGE_REVIEWERS_All_CodeReviewers: 'https://confluence.cbre.com/x/uh7JBg',
  // MANAGE_REVIEWERS_All_APIReviewers: 'https://confluence.cbre.com/x/uh7JBg',
  // SUBSCRIBED: 'https://confluence.cbre.com/x/GIoFBw',
  // TAXONOMY_PAGE: 'https://confluence.cbre.com/x/S5gFBw',
  // VIEW_TAXONOMY: 'https://confluence.cbre.com/x/TZgFBw',
  // ADD_CAPABILITY: 'https://confluence.cbre.com/x/T5gFBw',
  // GRAPH_TAXONOMY: 'TO be decided',
  // PROMOTION_MARKETING:
  //   'https://confluence.cbre.com/display/GA/Promotion+and+Marketing',
};

export default HELP_URL;
