/* eslint-disable react-hooks/exhaustive-deps */
import {
  Button,
  Card,
  CardActions,
  CardContent,
  Grid,
  Theme,
  Typography,
  createStyles,
  makeStyles,
} from '@material-ui/core';
import React, { useContext, useEffect, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router';
import { useStyles } from '../../../styles';
import BackButton from '../../../../utils/backButton';
import { HelpComponent } from '../../../../utils/helpComponent';
import HELP_URL from '../../../../utils/helpLinkConstant';
import { ITPContext, TPContext } from '../../../providers/TPProvider';
import ProjectInfo from './components/ProjectInfo';
import TestPlan from './components/TestPlan';
import LoadDistribution from './components/LoadDitribution';
import FailureCriteria from './components/FailureCriteria';
import { useGetConfigOptions } from '../../../hooks/useGetConfigOptions';
import { usePTOperations } from './hooks/usePTOperations';
import { IConfigData, IConfigObject } from '../../../interfaces';

const myStyles = makeStyles((theme: Theme) =>
  createStyles({
    // '@global': {
    //   'article > article': {
    //     height: '83%',
    //   },
    // },
  }),
);

const AddConfigPage = ({ isToolAdmin }: { isToolAdmin: boolean }) => {
  myStyles();
  const editState = useLocation().state as IConfigData;
  const classes = useStyles();
  const navigate = useNavigate();

  // const { projectId } = useParams();

  const { addConfig, editConfig } = usePTOperations('', false);

  const { apmInfo }: ITPContext = useContext(TPContext);
  const [configOptions, isLoading] = useGetConfigOptions(true);

  useEffect(() => {
    if (!apmInfo?.flows || !isToolAdmin) {
      navigate('../../../');
    }
  }, [apmInfo?.flows, isToolAdmin]);

  const sections = {
    PROJECT_INFO: 'projectInfo',
    TEST_PLAN: 'testPlan',
    LOAD_DISTRUBUTION: 'loadDistribution',
    FAILURE_CRITERIA: 'failureCriteria',
  };

  const sectionsArr = [
    sections.PROJECT_INFO,
    sections.TEST_PLAN,
    sections.LOAD_DISTRUBUTION,
    sections.FAILURE_CRITERIA,
  ];

  const [currentSection, setCurrentSection] = useState<string>(
    sections.PROJECT_INFO,
  );

  const [requestObject, setRequestObject] = useState<IConfigObject>({
    engineInstances: 1,
    splitAllCSVs: true,
    description: 'Performance test',
  });

  const updateRequestObject = (formObject: any) => {
    setRequestObject((prevState: any) => {
      return {
        ...prevState,
        ...formObject,
      };
    });
  };

  const [initialState, setInitialState] = useState<IConfigObject>({
    engineInstances: 1,
    splitAllCSVs: true,
    description: 'Performance test',
  });

  useEffect(() => {
    if (editState) {
      const initialTestPlanData = {
        repoBranchCloneUrl: editState.config?.repoBranchCloneUrl || '',
        testPlan: editState.config?.testPlan || '',
        userPropertyFile: editState.config?.userPropertyFile || '',
        secrets: editState.config?.secrets || [],
        configurationFiles: editState.config?.configurationFiles || [],
      };

      const initialProjectInfoData = {
        apmNumber: editState.config?.apmNumber || '',
        devxConfigName: editState.config?.devxConfigName || '',
        flowId: editState.config?.flowId || '',
      };
      setInitialState({
        description: editState.config?.description || '',
        engineInstances: editState.config?.engineInstance || 1,
        splitAllCSVs: editState.config?.splitAllCSVs || false,
        ...initialProjectInfoData,
        ...initialTestPlanData,
        globalTraffic: editState.config?.globalTraffic || [],
        failureCriteria: editState.config?.failureCriteria || [],
      });
    }
  }, [editState]);

  const handlePrevious = () => {
    const currentIndex = sectionsArr.findIndex(
      section => section === currentSection,
    );
    if (currentIndex > 0) setCurrentSection(sectionsArr[currentIndex - 1]);
  };
  const handleNext = (formObject: any) => {
    updateRequestObject(formObject);
    const currentIndex = sectionsArr.findIndex(
      section => section === currentSection,
    );
    if (currentIndex < sectionsArr.length - 1)
      setCurrentSection(sectionsArr[currentIndex + 1]);
    else {
      if (editState) {
        editConfig({
          ...requestObject,
          ...formObject,
          devxConfigId: editState.config?.devxConfigId,
        }).then(() => {
          navigate('../');
        });
      } else
        addConfig({ ...requestObject, ...formObject }).then(() => {
          navigate('../');
        });
    }
  };

  const navigateToManageConfig = () => {
    navigate('../', { replace: true });
  };

  return (
    <>
      <Grid container spacing={2}>
        <Grid
          item
          xs={12}
          sm={12}
          md={12}
          lg={12}
          className={classes.flexSpacebetween}
        >
          <BackButton />
          <HelpComponent helpUrl={HELP_URL.PERFORMANCE_TEST_ADDCONFIG_PAGE} />
        </Grid>
      </Grid>
      <Grid container className={classes.addConfigHeader}>
        <Grid item xs={6} sm={6} md={6} lg={6}>
          <Typography variant="h5">
            {editState ? 'Edit Config' : 'Add config'}
          </Typography>
        </Grid>
        <Grid
          item
          xs={6}
          sm={6}
          md={6}
          lg={6}
          className={classes.manageConfigHeaderButtons}
        >
          <Button
            variant="outlined"
            color="primary"
            onClick={navigateToManageConfig}
          >
            Cancel
          </Button>
        </Grid>
      </Grid>
      <div
        className={`${classes.addConfigFormContainer} ${classes.addConfigContainer}`}
      >
        {apmInfo?.flows && (
          <ProjectInfo
            apmInfo={apmInfo}
            onNext={handleNext}
            isVisible={currentSection === sections.PROJECT_INFO}
            initialState={initialState}
          />
        )}
        <TestPlan
          isVisible={currentSection === sections.TEST_PLAN}
          apmInfo={null}
          onPrevious={() => handlePrevious()}
          onNext={handleNext}
          initialState={initialState}
        />
        {configOptions?.globalTraffic && (
          <LoadDistribution
            isVisible={currentSection === sections.LOAD_DISTRUBUTION}
            apmInfo={null}
            onPrevious={() => handlePrevious()}
            onNext={handleNext}
            options={configOptions?.globalTraffic}
            initialState={initialState?.globalTraffic}
          />
        )}
        {configOptions?.failureCriteria && (
          <FailureCriteria
            isVisible={currentSection === sections.FAILURE_CRITERIA}
            apmInfo={null}
            onPrevious={() => handlePrevious()}
            onNext={handleNext}
            options={configOptions?.failureCriteria}
            initialState={initialState?.failureCriteria}
          />
        )}
      </div>
    </>
  );
};

export default AddConfigPage;
