import { useEffect, useState } from 'react';
import { IPTRun } from '../../../../interfaces';

export const useFilteredRuns = (
  runs: any,
  filterOption: Object,
  searchText: string,
  sort: string,
) => {
  const [filteredRuns, setFilteredRuns] = useState<IPTRun[]>([]);

  useEffect(() => {
    let localRuns: IPTRun[] = [];

    if (filterOption.status === 'All status') localRuns = [...runs];
    else {
      localRuns = runs?.filter(
        (run: IPTRun) => run.status === filterOption.status,
      );
    }

    if (filterOption.config === 'All configs') localRuns = [...localRuns];
    else {
      localRuns = localRuns?.filter(
        (run: IPTRun) => run.configName === filterOption.config,
      );
    }

    if (filterOption.region === 'All regions') localRuns = [...localRuns];
    else {
      localRuns = localRuns?.filter(
        (run: IPTRun) => run.region === filterOption.region,
      );
    }

    localRuns = localRuns?.filter((run: IPTRun) => {
      const logValues = Object.values(run);
      return logValues.some(
        value =>
          typeof value === 'string' &&
          value?.toLowerCase().includes(searchText.toLowerCase()),
      );
    });

    if (sort === 'descending') {
      localRuns = localRuns?.sort(
        (a: any, b: any) =>
          (new Date(b.requestTime) as any) - (new Date(a.requestTime) as any),
      );
    } else {
      localRuns = localRuns?.sort(
        (a: any, b: any) =>
          (new Date(a.requestTime) as any) - (new Date(b.requestTime) as any),
      );
    }
    setFilteredRuns(localRuns);
  }, [runs, filterOption, searchText, sort]);

  return { filteredRuns };
};
